










import React, { useContext, useState, useEffect } from 'react';
import { Button, Container, Row, Form, Col, InputGroup, Card } from 'react-bootstrap';
import { CartContext, useCart } from '../components/CartContext';
import '../css/Basket.css';
import TrashBox from '../photoNvabar/Navigation icon/trash.png';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { DELIVERYINFO_ROUTE, PDPPOLICY_ROUTE, POA_ROUTE } from '../utils/consts';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import AdList from '../components/AdList';
import { fetchAds, deleteAds, updateAds, createAds, fetchTypes } from '../http/productAPI';

const Basket = ({ typeId }) => {
    const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
    const navigate = useNavigate();
    const { setCartItems } = useCart();
    const [result, setResult] = useState("");
    const [selectedAd, setSelectedAd] = useState(null);
    const [adInfo, setAdInfo] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        numHome: '',
        podezd: '',
        floor: '',
        phone: '',
        email: '',
        pozhelaniya: '',
        paymentMethod: '',
        delivery: 'Доставка',
    });
    const [isDelivery, setIsDelivery] = useState(true);
    const [isPolicyChecked, setIsPolicyChecked] = useState(false);
    const [isPaymentSelected, setIsPaymentSelected] = useState(false);
    const [additionalDeliveryNeeded, setAdditionalDeliveryNeeded] = useState(false);
    const [errors, setErrors] = useState({});
    const [focusedInputId, setFocusedInputId] = useState(null);
    const [ads, setAds] = useState([]);

    const { loadProductsByType, product } = useCart();
    useEffect(() => {
        if (typeId) {
            loadProductsByType(typeId);
        }
    }, [typeId, loadProductsByType]);



    const handleFieldFocus  = (e) => {
    const { name } = e.target;

    // Сбрасываем ошибку при фокусе
    if (errors[name]) {
        setErrors({
            ...errors,
            [name]: false
        });
        }
    };
    const handleNavigation = (route) => {
        console.log('Navigating to:', route);
        navigate(route);
    };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value
    //     });
    // };
    const handleChange = (e) => {
    const { name, value } = e.target;

    // Обновляем данные формы
    setFormData({
        ...formData,
        [name]: value
    });

    // Сбрасываем ошибку при изменении значения в поле
    if (errors[name]) {
            setErrors({
                ...errors,
                [name]: false // Убираем ошибку
            });
        }
    };


    const handleFocus = (productId) => {
        setFocusedInputId(productId);
    };

    // const handleQuantityChange = (e, productId) => {
    //     const newQuantity = e.target.value;
    //     const product = cartItems.find(item => item.id === productId);

    //     if (newQuantity === '') {
    //         updateQuantity(productId, '');
    //         return;
    //     }

    //     const parsedQuantity = parseInt(newQuantity, 10);
    //     const minQuantity = product.typeId === 9 ? 2 : 1;

    //     if (!isNaN(parsedQuantity) && parsedQuantity >= minQuantity) {
    //         updateQuantity(productId, parsedQuantity);
    //     }
    // };

    // const handleBlur = (e, productId) => {
    //     const newQuantity = e.target.value;
    //     const product = cartItems.find(item => item.id === productId);
    //     const minQuantity = product.typeId === 9 ? 2 : 1;

    //     if (newQuantity === '' || isNaN(parseInt(newQuantity, 10)) || parseInt(newQuantity, 10) < minQuantity) {
    //         updateQuantity(productId, minQuantity);
    //     } else {
    //         updateQuantity(productId, parseInt(newQuantity, 10));
    //     }
    //     setFocusedInputId(null);
    // };
   const handleQuantityChange = (e, productId) => {
    const newQuantity = e.target.value;
    const product = cartItems.find(item => item.id === productId);

    if (newQuantity === '') {
        updateQuantity(productId, '');
        return;
    }

    const parsedQuantity = parseInt(newQuantity, 10);
    const minQuantity = 1; // Устанавливаем минимум 1 для всех типов, включая typeId === 9

    if (!isNaN(parsedQuantity) && parsedQuantity >= minQuantity) {
        updateQuantity(productId, parsedQuantity);
    }
    // Если условие не выполняется, ничего не обновляем
    };

    const handleBlur = (e, productId) => {
        const newQuantity = e.target.value;
        const product = cartItems.find(item => item.id === productId);
        const minQuantity = 1; // Устанавливаем минимум 1 для всех типов

        const parsedQuantity = parseInt(newQuantity, 10);

        if (newQuantity === '' || 
            isNaN(parsedQuantity) || 
            parsedQuantity < minQuantity) {
            updateQuantity(productId, minQuantity);
        } else {
            updateQuantity(productId, parsedQuantity);
        }
        setFocusedInputId(null);
    };
    const handleDeleteAd = async (id) => {
        try {
            await deleteAds(id);
            setAds(ads.filter(ad => ad.id !== id));
        } catch (error) {
            console.error('Error deleting ad:', error);
        }
    };

    const handleEditAd = (ad) => {
        setSelectedAd(ad);
        setAdInfo(ad.info);
    };

    const handleSaveAd = async () => {
        if (selectedAd) {
            await updateAds(selectedAd.id, { info: adInfo });
        } else {
            await createAds({ info: adInfo });
        }
        const adsData = await fetchAds();
        setAds(adsData);
        setSelectedAd(null);
        setAdInfo('');
    };

    const handlePaymentChange = (method) => {
        setFormData({
            ...formData,
            paymentMethod: method
        });
        setIsPaymentSelected(true);
    };

    const handleDeliveryChange = (deliveryMethod) => {
        setFormData({
            ...formData,
            delivery: deliveryMethod
        });
    };

    const handlePolicyChange = () => {
        setIsPolicyChecked(!isPolicyChecked);
    };

    const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

    useEffect(() => {
        if (totalPrice <= 600) {
            setResult("Сумма заказа ниже 600 рублей. Заказ невозможен.");
        } else if (totalPrice >= 600 && totalPrice <= 1000) {
            setAdditionalDeliveryNeeded(true);
            setResult("Сумма заказа ниже 1000 рублей. Дополнительная стоимость доставки 300 рублей.");
        } else {
            setAdditionalDeliveryNeeded(false);
            setResult("");
        }
    }, [totalPrice]);

    useEffect(() => {
        const fetchAdsData = async () => {
            try {
                const adsData = await fetchAds();
                setAds(adsData);
            } catch (error) {
                console.error('Error fetching ads:', error);
            }
        };

        fetchAdsData();
    }, []);

    const validateFields = () => {
        const newErrors = {};
        if (isDelivery) {
            if (!formData.firstName) newErrors.firstName = true;
            if (!formData.lastName) newErrors.lastName = true;
            if (!formData.address) newErrors.address = true;
            if (!formData.phone) newErrors.phone = true;
        } else {
            if (!formData.firstName) newErrors.firstName = true;
            if (!formData.lastName) newErrors.lastName = true;
            if (!formData.phone) newErrors.phone = true;
        }
        if (!formData.delivery) newErrors.delivery = true;
        if (!isPolicyChecked) newErrors.policy = true;
        if (!isPaymentSelected) newErrors.paymentMethod = true;

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!validateFields()) {
            return;
        }
    
        let totalOrderPrice = totalPrice;
        if (additionalDeliveryNeeded) {
            totalOrderPrice += 300;
        }
    
        const productDetails = cartItems
            .map(item => `${item.name} - ${item.quantity} шт. - ${item.price * item.quantity} руб.`)
            .join('\n');
    
        const formDataObj = new FormData();
    
        formDataObj.append("Способ доставки", formData.delivery);
        formDataObj.append("Имя", formData.firstName);
        formDataObj.append("Фамилия", formData.lastName);
        formDataObj.append("Адрес", formData.address);
        formDataObj.append("Номер квартиры/офиса", formData.numHome);
        formDataObj.append("Подъезд", formData.podezd);
        formDataObj.append("Этаж", formData.floor);
        formDataObj.append("Телефон", formData.phone);
        formDataObj.append("Пожелания к заказу", formData.pozhelaniya);
        formDataObj.append("Способ оплаты", formData.paymentMethod);
        formDataObj.append("Список товаров", productDetails);
        formDataObj.append("Итоговая цена", totalOrderPrice);
    
        try {
            const response = await fetch("https://admin.don-kulinar.ru/busket.php", {
                method: "POST",
                body: formDataObj,
            });
    
            const data = await response.json();
    
            if (data.success) {
                setResult("Form Submitted Successfully");
                navigate('/redirection');
                setCartItems([]);
            } else {
                console.error("Error", data);
                setResult(data.message);
            }
        } catch (error) {
            console.error("Error sending data to the server", error);
            setResult("Error sending data to the server");
        }
    };

    return (
        <>
            <Container>
                <div className='alert'>
                    <AdList ads={ads} onEdit={handleEditAd} onDelete={handleDeleteAd} />
                </div>
                <div style={{ marginTop: '30px' }} className='container'>
                    <Row>
                        <div className='con'>
                            <Col className='conn'>
                                <Button
                                    className='textDatauser'
                                    variant='none'
                                    onClick={() => {
                                        setIsDelivery(false);
                                        handleDeliveryChange('Самовывоз');
                                    }}
                                >Самовывоз</Button>
                                <Button
                                    className='textDatauser'
                                    variant='none'
                                    onClick={() => {
                                        setIsDelivery(true);
                                        handleDeliveryChange('Доставка');
                                    }}
                                >Доставка</Button>
                            </Col>
                            <Form onSubmit={handleSubmit}>
                                {isDelivery ? (
                                    <>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder='Имя'
                                                type="text"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                onFocus={handleFieldFocus}
                                                className={classNames('datauser', { error: errors.firstName })}
                                                style={{ height: '64px' }}
                                                onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                onKeyDown={(e) => {
                                                    if (e.ctrlKey && e.key === 'v') {
                                                    e.preventDefault(); // Блокируем Ctrl + V
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder='Фамилия'
                                                type="text"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                onFocus={handleFieldFocus}
                                                className={classNames('datauser', { error: errors.lastName })}
                                                style={{ height: '64px' }}
                                                onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                onKeyDown={(e) => {
                                                    if (e.ctrlKey && e.key === 'v') {
                                                    e.preventDefault(); // Блокируем Ctrl + V
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder='Название улицы и номер дома'
                                                type="text"
                                                name="numHome"
                                                value={formData.numHome}
                                                onChange={handleChange}
                                                onFocus={handleFieldFocus}
                                                className={classNames('datauser', { error: errors.numHome })}
                                                style={{ height: '64px' }}
                                                onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                onKeyDown={(e) => {
                                                    if (e.ctrlKey && e.key === 'v') {
                                                    e.preventDefault(); // Блокируем Ctrl + V
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder='Номер квартиры/офиса'
                                                type="text"
                                                name="address"
                                                value={formData.address}
                                                onFocus={handleFocus}
                                                onChange={handleChange}
                                                className={classNames('datauser')}
                                                style={{ height: '64px' }}
                                                onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                onKeyDown={(e) => {
                                                    if (e.ctrlKey && e.key === 'v') {
                                                    e.preventDefault(); // Блокируем Ctrl + V
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder='Номер подьезда'
                                                type="text"
                                                name="podezd"
                                                value={formData.podezd}
                                                onChange={handleChange}
                                                className={classNames('datauser')}
                                                style={{ height: '64px' }}
                                                onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                onKeyDown={(e) => {
                                                    if (e.ctrlKey && e.key === 'v') {
                                                    e.preventDefault(); // Блокируем Ctrl + V
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder='этаж'
                                                type="text"
                                                name="floor"
                                                value={formData.floor}
                                                onChange={handleChange}
                                                className={classNames('datauser')}
                                                style={{ height: '64px' }}
                                                onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                onKeyDown={(e) => {
                                                    if (e.ctrlKey && e.key === 'v') {
                                                    e.preventDefault(); // Блокируем Ctrl + V
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder='Телефон'
                                                type="number"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                onFocus={handleFieldFocus}
                                                className={classNames('datauser', { error: errors.phone })}
                                                style={{ height: '64px' }}
                                                onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                onKeyDown={(e) => {
                                                    if (e.ctrlKey && e.key === 'v') {
                                                    e.preventDefault(); // Блокируем Ctrl + V
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Control
                                                    placeholder='Пожелания к заказу'
                                                    as="textarea"
                                                    rows={3}
                                                    name="pozhelaniya"
                                                    value={formData.pozhelaniya}
                                                    onChange={handleChange}
                                                    className='pozhelaniya'
                                                    onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                    onKeyDown={(e) => {
                                                        if (e.ctrlKey && e.key === 'v') {
                                                        e.preventDefault(); // Блокируем Ctrl + V
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </InputGroup>
                                    </>
                                ) : (
                                    <>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder='Имя'
                                                type="text"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                onFocus={handleFieldFocus}
                                                className={classNames('datauser', { error: errors.firstName })}
                                                style={{ height: '64px' }}
                                                onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                onKeyDown={(e) => {
                                                    if (e.ctrlKey && e.key === 'v') {
                                                    e.preventDefault(); // Блокируем Ctrl + V
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder='Фамилия'
                                                type="text"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                onFocus={handleFieldFocus}
                                                className={classNames('datauser', { error: errors.lastName })}
                                                style={{ height: '64px' }}
                                                onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                onKeyDown={(e) => {
                                                    if (e.ctrlKey && e.key === 'v') {
                                                    e.preventDefault(); // Блокируем Ctrl + V
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                placeholder='Телефон'
                                                type="number"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                                onFocus={handleFieldFocus}
                                                className={classNames('datauser', { error: errors.phone })}
                                                style={{ height: '64px' }}
                                                onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                onKeyDown={(e) => {
                                                    if (e.ctrlKey && e.key === 'v') {
                                                    e.preventDefault(); // Блокируем Ctrl + V
                                                    }
                                                }}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Control
                                                    placeholder='Пожелания к заказу'
                                                    as="textarea"
                                                    rows={3}
                                                    name="pozhelaniya"
                                                    value={formData.pozhelaniya}
                                                    onChange={handleChange}
                                                    className='pozhelaniya'
                                                    onPaste={(e) => e.preventDefault()} // Блокируем вставку
                                                    onKeyDown={(e) => {
                                                        if (e.ctrlKey && e.key === 'v') {
                                                        e.preventDefault(); // Блокируем Ctrl + V
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </InputGroup>
                                    </>
                                )}
                            </Form>
                        </div>
                    </Row>

                    {cartItems.length > 0 && (
                        <Row className='mt-3' style={{ fontSize: '20px' }}>
                            <Card className='mx-auto' style={{ backgroundColor: '#ECECEC' }}>
                                <div style={{ fontSize: '25px', textAlign: 'center' }}>Заказ</div>
                                <Col>
                                   
                                    {cartItems.map((item) => (
                                        <div key={item.id} className='cart-item-row'>
                                            <div className='cart-item'>
                                                <div className='cart-item-name'>{item.name}</div>
                                                <div className='container_cart'>
                                                    <div className='quantity-controls'>
                                                        <input
                                                            type="number"
                                                            value={item.quantity}
                                                            min="1" // Устанавливаем минимум 1 для всех типов
                                                            onChange={(e) => handleQuantityChange(e, item.id)}
                                                            onFocus={() => handleFocus(item.id)}
                                                            onBlur={(e) => handleBlur(e, item.id)}
                                                        />
                                                    </div>
                                                    <div className='kolvo'> 
                                                        <FaCaretUp
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => updateQuantity(item.id, item.quantity + 1)}
                                                            className='plus'
                                                        />
                                                        <FaCaretDown
                                                            className='minus'
                                                            onClick={() => {
                                                                const newQuantity = item.quantity - 1;
                                                                if (newQuantity >= 1) { // Проверяем, чтобы не уйти ниже 1
                                                                    updateQuantity(item.id, newQuantity);
                                                                }
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='cart-item-price'>{item.price * item.quantity} руб.</div>
                                            </div>
                                            <Button variant="none" onClick={() => removeFromCart(item.id)} className='button-remove'>
                                                <img src={TrashBox} alt="Удалить" style={{ width: 20, height: 20 }} />
                                            </Button>
                                        </div>
                                    ))}
                                </Col>

                                <div className='checkbox-container'>
                                    <label className='checkbox-label1'>
                                        <input
                                            className='checkbox1'
                                            type="radio"
                                            name="paymentMethod"
                                            value="Наличные"
                                            checked={formData.paymentMethod === 'Наличные'}
                                            onChange={(e) => handlePaymentChange(e.target.value)}
                                        />
                                        Наличными
                                    </label>
                                    <label className='checkbox-label2'>
                                        <input
                                            className='checkbox2'
                                            type="radio"
                                            name="paymentMethod"
                                            value="Терминал"
                                            checked={formData.paymentMethod === 'Терминал'}
                                            onChange={(e) => handlePaymentChange(e.target.value)}
                                        />
                                        По терминалу
                                    </label>
                                </div>
                                <div className='agreement-text'>
                                    Нажимая кнопку «Заказать»<br />
                                    Я соглашаюсь с <a onClick={() => handleNavigation(DELIVERYINFO_ROUTE)} style={{textDecoration:'underline',color:'#0D6EFD',cursor:'pointer'}}>Условиями доставки</a> и 
                                    <a onClick={() => handleNavigation(POA_ROUTE)} style={{textDecoration:'underline',color:'#0D6EFD',cursor:'pointer'}}> Договором публичной оферты</a>
                                </div>
                                <div className='policy-checkbox'>
                                    <input
                                        type="checkbox"
                                        checked={isPolicyChecked}
                                        onChange={handlePolicyChange}
                                    />
                                    <span className={classNames('agreement-text2', { error: errors.policy })}>Я принимаю <a style={{textDecoration:'underline',color:'#0D6EFD',cursor:'pointer'}} onClick={() => handleNavigation(PDPPOLICY_ROUTE)}>Политику обработки персональных данных</a> *</span>
                                </div>

                                <div className='totalPrice' style={{ fontWeight: 'bold' }}>
                                    Итог:
                                    <span className='price'>{additionalDeliveryNeeded ? totalPrice + 300 : totalPrice} руб.</span>
                                </div>

                                {result && <p>{result}</p>}
                                <div className='d-flex justify-content-center'>
                                    <Button variant="dark" className='mt-4 mb-4' size='lg' onClick={handleSubmit}>
                                        ЗАКАЗАТЬ
                                    </Button>
                                </div>
                            </Card>
                        </Row>
                    )}
                </div>
            </Container>

            <div className='map-container'>
            <iframe
                src="https://yandex.ru/map-widget/v1/?um=constructor%3Abc6295aa3f7181674d18e59813f68e756a94a56307a24571e3f19b7eeb93e249&amp;source=constructor" 
                title="Iframe Example"
                style={{ border: 0, width: '100%', height: '500px' }}
                frameBorder="0"
            ></iframe>
               
            </div>
        </>
    );
};


export default Basket;